<template>
  <ValidationObserver slim v-slot="{ invalid, valid, untouched, handleSubmit }">
    <v-dialog :value="showDialog" :persistent="false" width="80vw" @input="closeDialog">
      <v-card>
        <v-card-title class="headline">
          <span>Редактирование статической страницы</span>
        </v-card-title>

        <v-card-text>
          <form>
            <v-layout column>
              <v-layout class="attachments" column>
                <v-tabs v-model="tab">
                  <v-tab key="images" href="#images">Дилер</v-tab>
                  <v-tabs-items v-model="tab">
                    <v-tab-item key="images" id="images" md-label="Дилер">
                      <form>
                        <v-layout column>
                          <v-layout column>
                            <v-layout class="mb-4" column>
                              <ValidationProvider name="question" rules="required"
                                                  v-slot="{ invalid, validated }">
                                <v-text-field label="Название *" v-model="title"
                                              hide-details="auto" required
                                              :error="invalid && validated"></v-text-field>
                              </ValidationProvider>
                              <ValidationProvider name="question" rules="required"
                                                  v-slot="{ invalid, validated }">
                                <v-text-field label="Символьный код *" v-model="code"
                                              hide-details="auto" required
                                              :error="invalid && validated"></v-text-field>
                              </ValidationProvider>
                            </v-layout>
                            <ckeditor editor-url="/js/ckeditor/ckeditor.js"
                                      v-model="content" :config="editorConfig"></ckeditor>
                            <div class="html-button">
                              <button type="button" @click="isOpen = !isOpen" class="btn btn-secondary">
                                <span>{{ isOpen ? 'Скрыть код' : 'Показать код' }}</span>
                              </button>
                            </div>
                            <Transition>
                              <div v-if="isOpen">
                                <h2 class="h4 mt-5">HTML Source</h2>
                                <textarea class="my-4 form-control" v-model="content" rows="50" />
                              </div>
                            </Transition>
                          </v-layout>
                        </v-layout>
                      </form>
                    </v-tab-item>
                  </v-tabs-items>
                </v-tabs>
              </v-layout>
            </v-layout>
          </form>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <CButton class="mr-3" color="secondary" @click="$emit('close')">Отмена</CButton>
          <CButton :disabled="invalid" @click="submit()" color="success">
            <i class="fa fa-save mr-2"></i>
            <span>Сохранить</span>
          </CButton>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </ValidationObserver>
</template>

<script>
import CKEditor from 'ckeditor4-vue';
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import EntityModalMixin from '@/views/grid_elements/mixins/EntityModalMixin';
import api from '@/api';

export default {
  name: "static-page-modal",
  components: {
    ValidationObserver,
    ValidationProvider,
    'ckeditor': CKEditor.component
  },
  mixins: [EntityModalMixin],
  async created() {
    try {
      const {
        data: {
          data: {
            id,
            content,
            old_content,
          }
        }
      } = await api.static_pages.staticPage(this.currentItem.type);

      this.id = id;
      this.content = content;
      this.old_content = old_content;
    } catch (e) {
      console.log(e)
    }
  },
  data() {
    let state = {
      id: null,
      tab: '',
      type: '',
      types: [{id: 'dealer', name: 'Дилер'}, {id: 'subdivision', name: 'Структурное подразделение'}],
      name: '',
      title: '',
      code: '',
      content: '',
      old_content: '',
      editorConfig: {
        allowedContent: true,
        height: '34vh',
        language: 'ru',
        readOnly: false,
      },
      isOpen: false,
    };

    if (this.currentItem) {
      state = {
        ...state,
        ...this.currentItem
      }
    }

    return state;
  },
  methods: {
    updateEntity: api.static_pages.updatePage,
    prepareUpdateEntityParams() {
      return [this.id, this.data];
    },
  },
  computed: {
    data() {
      return {
        id: this.id,
        title: this.title,
        code: this.code,
        content: this.content,
        old_content: this.old_content
      }
    }
  }
}
</script>

<style scoped lang="sass">
.html-button
  margin: 15px 0
  width: 250px

.v-enter-active,
.v-leave-active
  transition: opacity 0.5s ease


.v-enter-from,
.v-leave-to
  opacity: 0

</style>
